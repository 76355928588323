import { MvHomePage } from '@/lib/strapi-types/MvHomePage';
import { DevBackEndpoints } from '@/lib/types/enums/backEndpoints.enum';
import { Strapi4ResponseSingle } from '@nuxtjs/strapi/dist/runtime/types';
import { stringify } from 'qs';

export async function useHomePage() {
  const parsedQuery = stringify({
    populate: [
      'imageAndText1Section.CTA',
      'imageAndText1Section.image',
      'imageAndText1Section.icon',
      'imageAndText2Section.CTA',
      'imageAndText2Section.image',
      'imageAndText2Section.icon',
      'expertsSection.link',
      'expertsSection.CTA',
      'hero.CTA',
      'hero.image',
      'marketingPush.logo',
      'marketingPush.image.url',
      'marketingPush.image.alternativeText',
      'marketingPush.cta',
      'marketingPush.position'
    ]
  });

  const { data } = await useFetchWithHead<Strapi4ResponseSingle<MvHomePage['attributes']>>(
    `${useCompleteUrl(DevBackEndpoints.HOME_PAGE)}?${parsedQuery}`
  );

  const homePageData = data.value;

  return {
    imageAndText1Section: homePageData?.data.attributes.imageAndText1Section,
    imageAndText2Section: homePageData?.data.attributes.imageAndText2Section,
    expertsSection: homePageData?.data.attributes.expertsSection,
    hero: homePageData?.data.attributes.hero,
    marketingPush: homePageData?.data.attributes.marketingPush
  };
}
